<template>
  <div>
    <b-collapse class="panel" animation="slide">
      <template #trigger="props">
        <div class="panel-heading">
          <h2>{{ $tc("contract", 2) }}</h2>
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
        </div>
      </template>
      <div class="panel-block">
        <b-table
          :data="contracts"
          :loading="loading"
          default-sort="startDate"
          :row-class="(row) => !row.active && 'has-text-grey-light'"
        >
          <template #empty>
            {{ loading ? $t("loading") : $t("no_matching_results") }}
          </template>

          <b-table-column :label="$t('start_date')" field="startDate" sortable>
            <template v-slot="props">
              {{ props.row.startDate | date }}
            </template>
          </b-table-column>

          <b-table-column :label="$t('end_date')" field="endDate" sortable>
            <template v-slot="props">
              {{ props.row.endDate | date }}
            </template>
          </b-table-column>

          <b-table-column
            :label="$t('next_evaluation_date')"
            field="nextEvaluationDate"
            sortable
          >
            <template v-slot="props">
              {{ props.row.nextContractEvaluationDate | date }}
            </template>
          </b-table-column>

          <b-table-column numeric>
            <template v-slot="props">
              <b-button
                icon
                size="is-small"
                type="is-text"
                tag="router-link"
                :to="{ name: 'ContractView', params: { id: props.row.id } }"
              >
                <b-icon icon="arrow-right" size="is-small"></b-icon>
              </b-button>
            </template>
          </b-table-column>
        </b-table>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { mapActions } from "vuex";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      contracts: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getAllContracts"]),
    load() {
      this.getAllContracts({ company: this.company.id }).then(
        async (contracts) => {
          contracts.map((c) => {
            c.active =
              dayjs(c.startDate).isSameOrBefore(dayjs(), "day") &&
              dayjs(c.endDate).isSameOrAfter(dayjs(), "day");
          });
          this.contracts = contracts;

          this.loading = false;
        }
      );
    },
  },
};
</script>
