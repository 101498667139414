<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div
      v-if="!loading"
      class="is-flex is-flex-wrap-wrap is-align-items-center my-4"
    >
      <h1 class="mr-5">
        <span>{{ $tc("company", 2) }} &ndash;</span>
        {{ company.name }}
      </h1>
      <b-button
        v-if="userHasRoleSuperAdmin"
        type="is-primary"
        icon-left="pencil"
        tag="router-link"
        :to="{ name: 'CompanyEdit', params: { id: $route.params.id } }"
      >
        {{ $t("edit") }}
      </b-button>
      <b-button
        v-if="userHasRoleSuperAdmin && company.blocked"
        type="is-danger"
        icon-left="restore"
        class="ml-2"
        @click="activate"
      >
        {{ $t("activate") }}
      </b-button>
      <b-button
        v-if="userHasRoleSuperAdmin && !company.blocked"
        type="is-danger"
        icon-left="cancel"
        class="ml-2"
        @click="deactivate"
      >
        {{ $t("deactivate") }}
      </b-button>
    </div>

    <div v-if="!loading" class="card">
      <div class="card-content pt-3">
        <b-message
          v-if="company.blocked"
          type="is-danger"
          aria-close-label="Close message"
        >
          {{ $t("deactivated") }}
        </b-message>

        <BackButton :parent-route="{ name: 'CompanyIndex' }"></BackButton>

        <table class="table details-table">
          <tr>
            <th>{{ $t("region") }}</th>
            <td>{{ company.region | region }}</td>
          </tr>
          <tr>
            <th>{{ $t("name") }}</th>
            <td>{{ company.name }}</td>
          </tr>
          <tr>
            <th>{{ $t("email") }}</th>
            <td>
              <a
                v-if="company.emailAddress"
                :href="`mailto:${company.emailAddress}`"
              >
                {{ company.emailAddress }}
              </a>
            </td>
          </tr>
          <tr>
            <th>{{ $t("phone") }}</th>
            <td>{{ company.phoneNumber }}</td>
          </tr>
          <tr>
            <th>{{ $t("website") }}</th>
            <td>
              <a v-if="company.website" :href="company.website" target="_blank">
                {{ company.website }}
              </a>
            </td>
          </tr>
          <tr>
            <th>{{ $t("vat_number") }}</th>
            <td>{{ company.vatNumber }}</td>
          </tr>
          <tr>
            <th>{{ $t("address") }}</th>
            <td>
              <div v-if="company.shippingAddress">
                <p>{{ company.address.address }}</p>
                <p>{{ company.address.postcode }} {{ company.address.city }}</p>
                <p v-if="company.address.state">
                  {{ company.address.state }}
                </p>
                <p>{{ company.address.country | country }}</p>
              </div>
              <b-button
                v-if="userHasRoleSuperAdmin"
                :icon-left="company.address ? 'pencil' : 'plus'"
                class="mt-1"
                @click="showAddressModal = true"
              >
                <span v-if="company.address">
                  {{ $t("edit") }}
                </span>
                <span v-else>
                  {{ $t("add") }}
                </span>
              </b-button>
            </td>
          </tr>
          <tr>
            <th>{{ $t("shipping_address") }}</th>
            <td>
              <div v-if="company.shippingAddress">
                <p>{{ company.shippingAddress.address }}</p>
                <p>{{ company.shippingAddress.postcode }}</p>
                <p>{{ company.shippingAddress.city }}</p>
                <p v-if="company.shippingAddress.state">
                  {{ company.shippingAddress.state }}
                </p>
                <p>{{ company.shippingAddress.country }}</p>
              </div>
              <b-button
                v-if="userHasRoleSuperAdmin"
                :icon-left="company.shippingAddress ? 'pencil' : 'plus'"
                class="mt-1"
                @click="showShippingAddressModal = true"
              >
                <span v-if="company.shippingAddress">
                  {{ $t("edit") }}
                </span>
                <span v-else>
                  {{ $t("add") }}
                </span>
              </b-button>
            </td>
          </tr>
          <tr>
            <th>{{ $t("billing_information") }}</th>
            <td>
              <div v-if="company.billingInformation">
                <a :href="`mailto:${company.billingInformation.emailAddress}`">
                  {{ company.billingInformation.emailAddress }}
                </a>
                <p>{{ company.billingInformation.contact.name }}</p>
                <a
                  :href="`mailto:${company.billingInformation.contact.emailAddress}`"
                >
                  {{ company.billingInformation.contact.emailAddress }}
                </a>
                <p>{{ company.billingInformation.contact.phoneNumber }}</p>
                <p>{{ company.billingInformation.address.address }}</p>
                <p>
                  {{ company.billingInformation.address.postcode }}
                  {{ company.billingInformation.address.city }}
                </p>
                <p v-if="company.billingInformation.address.state">
                  {{ company.billingInformation.address.state }}
                </p>
                <p>
                  {{ company.billingInformation.address.country | country }}
                </p>
              </div>
              <b-button
                v-if="userHasRoleSuperAdmin"
                :icon-left="company.billingInformation ? 'pencil' : 'plus'"
                class="mt-1"
                @click="showBillingInformationModal = true"
              >
                <span v-if="company.billingInformation">
                  {{ $t("edit") }}
                </span>
                <span v-else>
                  {{ $t("add") }}
                </span>
              </b-button>
            </td>
          </tr>
          <tr>
            <th>{{ $t("commercial_contact") }}</th>
            <td>
              <div v-if="company.commercialContact">
                <p>{{ company.commercialContact.name }}</p>
                <a :href="`mailto:${company.commercialContact.emailAddress}`">
                  {{ company.commercialContact.emailAddress }}
                </a>
                <p>{{ company.commercialContact.phoneNumber }}</p>
              </div>
              <b-button
                v-if="userHasRoleSuperAdmin"
                :icon-left="company.commercialContact ? 'pencil' : 'plus'"
                class="mt-1"
                @click="showCommercialContactModal = true"
              >
                <span v-if="company.commercialContact">
                  {{ $t("edit") }}
                </span>
                <span v-else>
                  {{ $t("add") }}
                </span>
              </b-button>
            </td>
          </tr>
          <tr>
            <th>{{ $t("operational_contact") }}</th>
            <td>
              <div v-if="company.operationalContact">
                <p>{{ company.operationalContact.name }}</p>
                <a :href="`mailto:${company.operationalContact.emailAddress}`">
                  {{ company.operationalContact.emailAddress }}
                </a>
                <p>{{ company.operationalContact.phoneNumber }}</p>
              </div>
              <b-button
                v-if="userHasRoleSuperAdmin"
                :icon-left="company.operationalContact ? 'pencil' : 'plus'"
                class="mt-1"
                @click="showOperationalContactModal = true"
              >
                <span v-if="company.operationalContact">
                  {{ $t("edit") }}
                </span>
                <span v-else>
                  {{ $t("add") }}
                </span>
              </b-button>
            </td>
          </tr>
          <tr>
            <th>{{ $tc("currency", 1) }}</th>
            <td>{{ company.currency.name }} ({{ company.currency.code }})</td>
          </tr>
          <tr>
            <th>{{ $t("consent") }}</th>
            <td>
              {{ company.supportAndAnalysisConsent ? $t("yes") : $t("no") }}
            </td>
          </tr>
          <tr>
            <th>{{ $tc("system_of_measurement", 1) }}</th>
            <td>{{ company.systemOfMeasurement.name }}</td>
          </tr>
          <tr>
            <th>{{ $t("created") }}</th>
            <td>
              {{ company.createdAt | dateTime }}
            </td>
          </tr>
          <tr>
            <th>{{ $t("updated") }}</th>
            <td>
              {{ company.updatedAt | dateTime }}
            </td>
          </tr>
        </table>

        <CompanyUsers
          v-if="userHasRoleSuperAdmin"
          :company="company"
        ></CompanyUsers>

        <Contracts
          v-if="userHasRoleSuperAdmin"
          :company="company"
          class="mt-5"
        ></Contracts>

        <Partners
          v-if="userHasRoleSuperAdmin"
          :company="company"
          class="mt-5"
        ></Partners>
      </div>
    </div>

    <b-modal
      v-if="userHasRoleSuperAdmin && !loading"
      v-model="showAddressModal"
      :width="600"
      scroll="keep"
    >
      <EditAddressModal
        :company="company"
        :type="'address'"
        @submit="load"
      ></EditAddressModal>
    </b-modal>

    <b-modal
      v-if="userHasRoleSuperAdmin && !loading"
      v-model="showShippingAddressModal"
      :width="600"
      scroll="keep"
    >
      <EditAddressModal
        :company="company"
        :type="'shippingAddress'"
        @submit="load"
      ></EditAddressModal>
    </b-modal>

    <b-modal
      v-if="userHasRoleSuperAdmin && !loading"
      v-model="showBillingInformationModal"
      :width="600"
      scroll="keep"
    >
      <EditBillingInformationModal
        :company="company"
        @submit="load"
      ></EditBillingInformationModal>
    </b-modal>

    <b-modal
      v-if="userHasRoleSuperAdmin && !loading"
      v-model="showCommercialContactModal"
      :width="400"
      scroll="keep"
    >
      <CreateOrEditContactModal
        :company="company"
        :type="'commercialContact'"
        @submit="load"
      ></CreateOrEditContactModal>
    </b-modal>

    <b-modal
      v-if="userHasRoleSuperAdmin && !loading"
      v-model="showOperationalContactModal"
      :width="400"
      scroll="keep"
    >
      <CreateOrEditContactModal
        :company="company"
        :type="'operationalContact'"
        @submit="load"
      ></CreateOrEditContactModal>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BackButton from "../../components/BackButton";
import Partners from "../../components/Partners";
import CompanyUsers from "./partials/CompanyUsers";
import Contracts from "./partials/Contracts";
import CreateOrEditContactModal from "./partials/CreateOrEditContactModal";
import EditAddressModal from "./partials/EditAddressModal";
import EditBillingInformationModal from "./partials/EditBillingInformationModal";

export default {
  name: "CompanyView",
  components: {
    BackButton,
    CompanyUsers,
    Contracts,
    CreateOrEditContactModal,
    EditAddressModal,
    EditBillingInformationModal,
    Partners,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      showAddressModal: false,
      showShippingAddressModal: false,
      showBillingInformationModal: false,
      showCommercialContactModal: false,
      showOperationalContactModal: false,
      company: null,
    };
  },
  computed: {
    ...mapGetters([
      "findCurrencyByUri",
      "findSystemOfMeasurementByUri",
      "userHasRoleSuperAdmin",
    ]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["updateCompany", "getCompany"]),
    load() {
      this.loading = true;

      this.getCompany(this.id).then(async (company) => {
        company.currency = this.findCurrencyByUri(company.currency["@id"]);
        company.systemOfMeasurement = this.findSystemOfMeasurementByUri(
          company.systemOfMeasurement["@id"]
        );
        this.company = company;

        this.loading = false;
      });
    },
    activate() {
      this.$buefy.dialog.confirm({
        title: this.$t("company_activate"),
        message: this.$t("activate_confirm"),
        cancelText: this.$t("cancel"),
        confirmText: this.$t("yes"),
        type: "is-danger",
        onConfirm: () => {
          this.loading = true;
          this.updateCompany({
            id: this.company.id,
            company: { blocked: false },
          })
            .then(() => {
              this.$buefy.toast.open({
                message: this.$t("activate_confirm"),
                type: "is-primary",
              });
            })
            .finally(() => {
              this.load();
            });
        },
      });
    },
    deactivate() {
      this.$buefy.dialog.confirm({
        title: this.$t("company_deactivate"),
        message: this.$t("deactivate_confirm"),
        cancelText: this.$t("cancel"),
        confirmText: this.$t("yes"),
        type: "is-danger",
        onConfirm: () => {
          this.loading = true;
          this.updateCompany({
            id: this.company.id,
            company: { blocked: true },
          })
            .then(() => {
              this.$buefy.toast.open({
                message: this.$t("deactivate_confirm"),
                type: "is-primary",
              });
            })
            .finally(() => {
              this.load();
            });
        },
      });
    },
  },
};
</script>
