<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ company[type] ? $t("contact_edit") : $t("contact_add") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2" @submit.prevent="submit">
        <CustomInput v-model="form.name" :label="$t('name')"></CustomInput>

        <CustomInput
          v-model="form.emailAddress"
          :label="$t('email')"
          :type="'email'"
        ></CustomInput>

        <CustomInput
          v-model="form.phoneNumber"
          :label="$t('phone')"
        ></CustomInput>

        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-5"
          icon-left="content-save"
          :loading="submitting"
        >
          {{ $t("save") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CustomInput from "../../../components/forms/CustomInput";

export default {
  components: { CustomInput },
  props: {
    company: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      form: {
        name: this.company[this.type]?.name,
        emailAddress: this.company[this.type]?.emailAddress,
        phoneNumber: this.company[this.type]?.phoneNumber,
      },
    };
  },
  methods: {
    ...mapActions(["createContact", "updateCompany", "updateContact"]),
    async submit() {
      this.submitting = true;

      if (this.company[this.type]) {
        await this.updateContact({
          id: this.company[this.type].id,
          contact: this.form,
        });
      } else {
        const contact = await this.createContact(this.form);
        await this.updateCompany({
          id: this.company.id,
          company: { [this.type]: contact["@id"] },
        });
      }

      this.$parent.close();
      this.$emit("submit");
    },
  },
};
</script>
