<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ $t("address_edit") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2" @submit.prevent="submit">
        <CompanyFormAddress :address="form"></CompanyFormAddress>

        <b-field v-if="type === 'address'" horizontal class="mt-5">
          <b-checkbox v-model="copyToShippingAddress">
            {{ $t("copy_to_shipping_address") }}
          </b-checkbox>
        </b-field>

        <b-field v-if="type === 'address'" horizontal>
          <b-checkbox v-model="copyToBillingInformation">
            {{ $t("copy_to_billing_information") }}
          </b-checkbox>
        </b-field>

        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-5"
          icon-left="content-save"
          :loading="submitting"
        >
          {{ $t("save") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CompanyFormAddress from "./CompanyFormAddress";

export default {
  components: { CompanyFormAddress },
  props: {
    company: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      copyToShippingAddress: false,
      copyToBillingInformation: false,
      form: {
        address: this.company[this.type].address,
        city: this.company[this.type].city,
        postcode: this.company[this.type].postcode,
        state: this.company[this.type].state,
        country: this.company[this.type].country,
      },
    };
  },
  methods: {
    ...mapActions(["updateAddress"]),
    async submit() {
      this.submitting = true;

      await this.updateAddress({
        id: this.company[this.type].id,
        address: this.form,
      });

      if (this.copyToShippingAddress) {
        await this.updateAddress({
          id: this.company.shippingAddress.id,
          address: this.form,
        });
      }
      if (this.copyToBillingInformation) {
        await this.updateAddress({
          id: this.company.billingInformation.address.id,
          address: this.form,
        });
      }

      this.$emit("submit");
      this.$parent.close();
    },
  },
};
</script>
