<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ $t("company_user_add") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2" @submit.prevent="submit">
        <UserAutocomplete
          :disabled-users="disabledUsers"
          :horizontal="false"
          @selected="(e) => (form.userAccount = e ? e['@id'] : null)"
        ></UserAutocomplete>

        <b-field :label="$tc('role')" label-for="role">
          <b-select id="role" v-model="form.role" required>
            <option
              v-for="r in roles"
              :key="r.role"
              :value="r.role"
              :disabled="r.disabled"
            >
              {{ $t(r.role) }}
              {{ r.disabled ? ` (${$t("limit_reached")})` : "" }}
            </option>
          </b-select>
        </b-field>

        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-3"
          icon-left="content-save"
          :loading="submitting"
          :disabled="!valid"
        >
          {{ $t("save") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash-es";
import { mapActions } from "vuex";
import UserAutocomplete from "../../../components/forms/UserAutocomplete";

export default {
  components: { UserAutocomplete },
  props: {
    company: {
      type: Object,
      required: true,
    },
    companyUsers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      fetching: false,
      users: [],
      disabledUsers: [],
      roles: [
        { role: "ROLE_COMPANY_USER" },
        { role: "ROLE_COMPANY_ADMIN", disabled: true },
      ],
      form: {
        role: "ROLE_COMPANY_USER",
        userAccount: null,
      },
    };
  },
  computed: {
    valid() {
      return this.form.role && this.form.userAccount;
    },
  },
  mounted() {
    // Only allow company admin role when limit (2) not reached
    const admins = this.companyUsers.filter(
      (cu) => !cu.blocked && cu.roles.includes("ROLE_COMPANY_ADMIN")
    ).length;
    if (admins < 2) {
      this.roles.find((r) => r.role === "ROLE_COMPANY_ADMIN").disabled = false;
    }

    // Show only super admin option when company is Reduct
    if (this.company.name === "Reduct N.V.") {
      this.roles = [{ role: "ROLE_SUPERADMIN" }];
      this.form.role = "ROLE_SUPERADMIN";
    }

    this.disabledUsers = this.companyUsers.map((cu) => {
      return { ["@id"]: cu.userAccount["@id"] };
    });
  },
  methods: {
    ...mapActions(["createCompanyUser", "getUsers"]),
    getAsyncData: debounce(function (query) {
      if (!query) {
        return;
      }
      this.fetching = true;
      this.getUsers({ emailAddress: query }).then((users) => {
        this.users = users.items;
        this.fetching = false;
      });
    }, 200),
    submit() {
      this.submitting = true;
      this.createCompanyUser({
        company: this.company["@id"],
        userAccount: this.form.userAccount,
        roles: [this.form.role],
      })
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t("company_user_added"),
            type: "is-primary",
          });
          this.$emit("submit");
          this.$parent.close();
        })
        .catch(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
