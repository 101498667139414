<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ $t("company_user_edit") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2" @submit.prevent="submit">
        <CustomInput
          v-model="companyUser.userAccount.emailAddress"
          :label="$tc('user')"
          :horizontal="false"
          :disabled="true"
        ></CustomInput>

        <b-field :label="$tc('role')" label-for="role">
          <b-select
            id="role"
            v-model="form.role"
            :disabled="onlyAdmin"
            required
          >
            <option
              v-for="r in roles"
              :key="r.role"
              :value="r.role"
              :disabled="r.disabled"
            >
              {{ $t(r.role) }}
              {{ r.disabled ? ` (${$t("limit_reached")})` : "" }}
            </option>
          </b-select>
          <small v-if="onlyAdmin">{{ $t("only_admin") }}</small>
        </b-field>

        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-5"
          icon-left="content-save"
          :loading="submitting"
        >
          {{ $t("save") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CustomInput from "../../../components/forms/CustomInput";

export default {
  components: { CustomInput },
  props: {
    company: {
      type: Object,
      required: true,
    },
    companyUser: {
      type: Object,
      required: true,
    },
    companyUsers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      roles: [
        { role: "ROLE_COMPANY_USER" },
        { role: "ROLE_COMPANY_ADMIN", disabled: true },
      ],
      form: {
        role: this.companyUser.roles.includes("ROLE_COMPANY_ADMIN")
          ? "ROLE_COMPANY_ADMIN"
          : "ROLE_COMPANY_USER",
      },
      onlyAdmin: false,
    };
  },
  mounted() {
    // Only allow company admin role when limit (2) not reached
    const admins = this.companyUsers.filter(
      (cu) => !cu.blocked && cu.roles.includes("ROLE_COMPANY_ADMIN")
    ).length;
    if (admins < 2) {
      this.roles.find((r) => r.role === "ROLE_COMPANY_ADMIN").disabled = false;
    }

    // Disable role change when company user is the only company admin
    if (admins === 1 && this.companyUser.roles.includes("ROLE_COMPANY_ADMIN")) {
      this.onlyAdmin = true;
    }

    // Show only the super admin option when company is Reduct
    if (this.company.name === "Reduct N.V.") {
      this.roles = [{ role: "ROLE_SUPERADMIN" }];
      this.form.role = "ROLE_SUPERADMIN";
      this.onlyAdmin = false;
    }
  },
  methods: {
    ...mapActions(["updateCompanyUser"]),
    submit() {
      this.submitting = true;
      this.updateCompanyUser({
        id: this.companyUser.id,
        companyUser: {
          roles: [this.form.role],
        },
      })
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t("company_user_updated"),
            type: "is-primary",
          });
          this.$emit("submit");
          this.$parent.close();
        })
        .catch(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
