<template>
  <div>
    <b-collapse class="panel" animation="slide">
      <template #trigger="props">
        <div class="panel-heading">
          <h2>{{ $tc("user", 2) }}</h2>
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
        </div>
      </template>
      <div class="panel-block">
        <b-button icon-left="plus" @click="toggleCompanyUserAddModal">
          {{ $t("company_user_add") }}
        </b-button>
        <b-table
          :data="companyUsers"
          :loading="loading"
          default-sort="userAccount.emailAddress"
          class="mt-4"
          :row-class="(row) => row.blocked && 'has-text-grey-light'"
        >
          <template #empty>
            {{ loading ? $t("loading") : $t("no_matching_results") }}
          </template>

          <b-table-column
            :label="$t('email')"
            field="userAccount.emailAddress"
            sortable
          >
            <template v-slot="props">
              {{ props.row.userAccount.emailAddress }}
            </template>
          </b-table-column>

          <b-table-column
            :label="$t('phone')"
            field="userAccount.phoneNumber"
            sortable
          >
            <template v-slot="props">
              {{ props.row.userAccount.phoneNumber }}
            </template>
          </b-table-column>

          <b-table-column
            :label="$tc('language', 1)"
            field="language.isoCode"
            sortable
          >
            <template v-slot="props">
              {{ props.row.userAccount.language.isoCode | uc }}
            </template>
          </b-table-column>

          <b-table-column
            :label="$tc('role')"
            field="userAccount.roles"
            sortable
          >
            <template v-slot="props">
              {{ props.row.roles | highestCompanyRole }}
            </template>
          </b-table-column>

          <b-table-column numeric field="row.blocked" sortable>
            <template v-slot="props">
              <b-tooltip
                :label="$t('user_block')"
                type="is-light"
                :class="{
                  'is-invisible':
                    props.row.roles.includes('ROLE_COMPANY_ADMIN'),
                }"
              >
                <b-button
                  v-if="!props.row.blocked"
                  icon
                  size="is-small"
                  type="is-text"
                  @click="blockCompanyUser(props.row)"
                >
                  <b-icon icon="cancel" size="is-small"></b-icon>
                </b-button>
              </b-tooltip>

              <b-tooltip :label="$t('user_unblock')" type="is-light">
                <b-button
                  v-if="props.row.blocked"
                  icon
                  size="is-small"
                  type="is-text"
                  @click="unblockCompanyUser(props.row)"
                >
                  <b-icon icon="restore" size="is-small"></b-icon>
                </b-button>
              </b-tooltip>

              <b-button
                icon
                size="is-small"
                type="is-text"
                @click="toggleCompanyUserEditModal(props.row)"
              >
                <b-icon icon="pencil" size="is-small"></b-icon>
              </b-button>

              <b-button
                icon
                size="is-small"
                type="is-text"
                tag="router-link"
                :to="{
                  name: 'UserView',
                  params: { id: props.row.userAccount.id },
                }"
              >
                <b-icon icon="arrow-right" size="is-small"></b-icon>
              </b-button>
            </template>
          </b-table-column>
        </b-table>
      </div>
    </b-collapse>

    <b-modal v-model="showCompanyUserModal" :width="400" scroll="keep">
      <CompanyUserUpdateModal
        v-if="companyUserToEdit"
        :company="company"
        :company-user="companyUserToEdit"
        :company-users="companyUsers"
        @submit="load"
      ></CompanyUserUpdateModal>
      <CompanyUserAddModal
        v-else
        :company="company"
        :company-users="companyUsers"
        @submit="load"
      ></CompanyUserAddModal>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CompanyUserAddModal from "./CompanyUserAddModal";
import CompanyUserUpdateModal from "./CompanyUserUpdateModal";

export default {
  components: { CompanyUserAddModal, CompanyUserUpdateModal },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      companyUsers: [],
      showCompanyUserModal: false,
      companyUserToEdit: null,
    };
  },
  computed: {
    ...mapGetters(["languageById"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getAllCompanyUsers", "updateCompanyUser"]),
    load() {
      this.getAllCompanyUsers(this.company.id).then(async (companyUsers) => {
        this.companyUsers = companyUsers;
        this.loading = false;
      });
    },
    toggleCompanyUserAddModal() {
      this.companyUserToEdit = null;
      this.showCompanyUserModal = true;
    },
    toggleCompanyUserEditModal(companyUser) {
      this.companyUserToEdit = companyUser;
      this.showCompanyUserModal = true;
    },
    blockCompanyUser(companyUser) {
      this.$buefy.dialog.confirm({
        title: this.$t("user_block"),
        message: this.$t("user_block_confirm"),
        cancelText: this.$t("cancel"),
        confirmText: this.$t("yes"),
        type: "is-danger",
        onConfirm: async () => {
          this.loading = true;
          await this.updateCompanyUser({
            id: companyUser.id,
            companyUser: { blocked: true },
          });
          this.load();
        },
      });
    },
    unblockCompanyUser(companyUser) {
      this.$buefy.dialog.confirm({
        title: this.$t("user_unblock"),
        message: this.$t("user_unblock_confirm"),
        cancelText: this.$t("cancel"),
        confirmText: this.$t("yes"),
        type: "is-danger",
        onConfirm: async () => {
          this.loading = true;
          await this.updateCompanyUser({
            id: companyUser.id,
            companyUser: { blocked: false },
          });
          this.load();
        },
      });
    },
  },
};
</script>
