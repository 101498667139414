<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ $t("billing_information_edit") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2" @submit.prevent="submit">
        <CompanyFormBillingInformation
          :billing-information="form"
          :check-mimics-address="billingInformationMatchesAddress"
          @mimics="mimicsAddress"
        ></CompanyFormBillingInformation>

        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-5"
          icon-left="content-save"
          :loading="submitting"
        >
          {{ $t("save") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { isMatch, pick } from "lodash-es";
import { mapActions } from "vuex";
import CompanyFormBillingInformation from "./CompanyFormBillingInformation";

export default {
  components: { CompanyFormBillingInformation },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      form: {
        emailAddress: this.company.billingInformation.emailAddress,
        contact: {
          name: this.company.billingInformation.contact.name,
          emailAddress: this.company.billingInformation.contact.emailAddress,
          phoneNumber: this.company.billingInformation.contact.phoneNumber,
        },
        address: {
          address: this.company.billingInformation.address.address,
          city: this.company.billingInformation.address.city,
          postcode: this.company.billingInformation.address.postcode,
          state: this.company.billingInformation.address.state,
          country: this.company.billingInformation.address.country,
        },
      },
    };
  },
  computed: {
    billingInformationMatchesAddress() {
      return isMatch(
        pick(this.company.billingInformation.address, [
          "address",
          "city",
          "postcode",
          "state",
          "country",
        ]),
        pick(this.company.address, [
          "address",
          "city",
          "postcode",
          "state",
          "country",
        ])
      );
    },
  },
  methods: {
    ...mapActions([
      "updateAddress",
      "updateBillingInformation",
      "updateContact",
    ]),
    mimicsAddress(value) {
      if (!value) {
        return;
      }
      this.form.address = {
        address: this.company.address.address,
        city: this.company.address.city,
        postcode: this.company.address.postcode,
        state: this.company.address.state,
        country: this.company.address.country,
      };
    },
    async submit() {
      this.submitting = true;
      await this.updateBillingInformation({
        id: this.company.billingInformation.id,
        billingInformation: {
          emailAddress: this.form.emailAddress,
        },
      });
      await this.updateContact({
        id: this.company.billingInformation.contact.id,
        contact: this.form.contact,
      });
      await this.updateAddress({
        id: this.company.billingInformation.address.id,
        address: this.form.address,
      });
      this.submitting = false;
      this.$parent.close();
      this.$emit("submit");
    },
  },
};
</script>
